import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getDataFromServer } from '../shared/utilities'

export const GlobalStateContext = React.createContext()

const GlobalContextProvider = ({ children }) => {
  const [events, setEvents] = useState([])

  function getTime(time) {
    const arr = time.split('-')
    const day = arr[0]
    const month = arr[1]
    const year = arr[2]
    return new Date(year, month, day)
  }

  useEffect(() => {
    const endpointEvents = 'https://www.missionone.com.au/api/events/'
    const token = process.env.GATSBY_MISSION_ONE_API_TOKEN

    if (!token) console.log('No MissionOne Token - fetch will fail')

    const MISSIONS_INTERLINK_ID = 902
    getDataFromServer(endpointEvents, token).then(event => {
      const miEvents = event.filter(item => item.charity.id === MISSIONS_INTERLINK_ID)
      const sortedEvents = miEvents.sort((a, b) =>
        getTime(a.startDate) > getTime(b.startDate) ? 1 : -1
      )
      setEvents(sortedEvents)
    })
  }, [])

  return <GlobalStateContext.Provider value={{ events }}>{children}</GlobalStateContext.Provider>
}

export default GlobalContextProvider

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}
