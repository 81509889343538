export const pluck = (objectArray, element) => {
  const uniqueList = []
  objectArray.forEach(item => {
    if (Object.prototype.hasOwnProperty.call(item, element)) {
      if (!uniqueList.includes(item[element])) uniqueList.push(item[element])
    }
  })
  return uniqueList.sort()
}

export const pluck2 = (objectArray, element) => {
  return Array.from(new Set(objectArray.map(item => item[element])))
}

export function getFormattedDate(time) {
  const arr = time.split('-')
  const year = arr[2]
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const month = months[parseInt(arr[1], 10) - 1]
  const day = arr[0]
  return `${day} ${month} ${year}`
}

export const pluckGraphQL = (objectArray, element) => {
  const uniqueList = []
  objectArray.forEach(item => {
    if (Object.prototype.hasOwnProperty.call(item.node, element)) {
      if (!uniqueList.includes(item.node[element])) uniqueList.push(item.node[element])
    }
  })
  return uniqueList.sort()
}

export const getDataFromServer = async (endpoint, token = null, useCache = true) => {
  const cache = useCache ? 'default' : 'no-cache'
  const data = await fetch(endpoint, {
    cache,
    method: 'GET',
    headers: {
      Authorization: `token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (!response.ok) throw Error(response.statusText)
      return response
    })
    .then(response => response.json())

  return data
}

export function getSummaryFromObject(objectData, objectKey, limit = 10) {
  const summary = new Map()
  if (!objectData) return []
  if (objectData.length < 1) return []

  objectData.forEach(item => {
    const key = item[objectKey]
    if (summary.has(key)) {
      const count = summary.get(key)
      summary.set(key, count + 1)
    } else {
      summary.set(key, 1)
    }
  })

  // convert map to array
  const summaryArray = []
  summary.forEach((value, key) => {
    const percent = ((value / objectData.length) * 100).toFixed(1)
    const object = { key, value, percent }
    summaryArray.push(object)
  })

  summaryArray.sort((a, b) => {
    return b.value - a.value
  })

  return summaryArray.slice(0, limit)
}

export const shorten = (str, maxLen, separator = ' ') => {
  if (str.length <= maxLen) return str
  return str.substr(0, str.lastIndexOf(separator, maxLen))
}

export function rightBack(str, separator) {
  const arr = str.split(separator)
  return arr[arr.length - 1] || ''
}

// Get values from deeply nested object values

export function getContentfulUrl(imageField) {
  return imageField && 'file' in imageField && 'url' in imageField.file ? imageField.file.url : ''
}

export function getEmbeddedContentfulUrl(fields, imageField) {
  return imageField in fields && 'fields' in fields.image['en-US']
    ? fields[imageField]['en-US'].fields.file['en-US'].url
    : ''
}

export function getFieldValue(fields, fieldName) {
  return fields && fieldName in fields ? fields[fieldName]['en-US'] : null
}

export function getSafe(fn, defaultVal = null) {
  try {
    return fn()
  } catch (e) {
    return defaultVal
  }
}

export const get = (p, obj) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), obj)

// used to get random testimonials
const getRandomInt = max => Math.floor(Math.random() * Math.floor(max))

export const getRandomUniqueArray = (length, max) => {
  if (length > max) return []
  const arr = []

  while (arr.length < length) {
    const random = getRandomInt(max)
    if (!arr.includes(random)) arr.push(random)
  }
  return arr
}

/**
 * Filters an array of objects using custom predicates.
 * https://gist.github.com/jherax/f11d669ba286f21b7a2dcff69621eb72
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
export function filterArray(array, filters) {
  const filterKeys = Object.keys(filters)
  return array.filter(item => {
    // validates all filter criteria
    return filterKeys.every(key => {
      // ignores non-function predicates
      if (typeof filters[key] !== 'function') return true
      return filters[key](item[key])
    })
  })
}

/**
 * Concatenate two relative urls when we don't know if urls have leading/trailing slashes
 * @param  {String} url1: first relative url
 * @param  {String} url2: second relative url
 * @return {String}
 */
export function urlConcat(url1, url2) {
  const url1Clean = url1.endsWith('/') ? url1.slice(0, url1.length - 1) : url1
  const url2Clean = url2.startsWith('/') ? url2.slice(1) : url2
  return `${url1Clean}/${url2Clean}`
}

export const sortBySortOrder = (a, b) => {
  if (!a.sortOrder) return 1
  if (!b.sortOrder) return -1

  return a.sortOrder - b.sortOrder
}
